// These styles apply to every route in the application
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import "@/styles/globals.css";
import Head from "next/head";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

export default function MyApp({ Component, pageProps }: AppProps) {
  const tagManagerArgs = {
    gtmId: "GTM-5XDNCGZ",
  };
  useEffect(() => {
    const delay = 4000;
    const timer = setTimeout(() => {
      TagManager.initialize(tagManagerArgs);
    }, delay);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Component {...pageProps}>
      <html lang="pt" className="bg-violet-700 w-full h-full flex">
        <Head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            rel="preload"
            href="/fonts/lato.woff2"
            as="font"
            type="font/woff2"
          />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />

          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />

          <meta
            name="description"
            content="Realize sorteios a partir dos comentários do Instagram. A única plataforma que consegue carregar todos os comentários."
          />
          <meta
            name="keywords"
            content="sorteio, instagram, comentários, insta, promoção, sorteios, instagram sorteio, instagram sorteios, sorteio instagram, sorteio de instagram, sorteio no instagram, sorteio no insta, sorteio instagram online, sorteio instagram app, sorteio instagram app, sorteio instagram aplicativo, sorteio instagram aplicativo, sorteio, sorteio Instagram, como fazer sorteio no instagram, sorteio instagram regras, sorteio instagram online, sorteio no Instagram, sorteio no facebook, sorteio facebook online, sorteio aleatório, sorteio de nomes, giveaway, instagram comment picker, instagram contests, free instagram giveaway, facebook comment picker, random comment picker, random winners"
          />
          <meta name="author" content="InstaSorteio" />
          <meta name="robots" content="index, nofollow" />

          <meta property="og:url" content="https://www.instasorteio.com.br" />
          <meta
            property="og:title"
            content="InstaSorteio - Sorteio com comentários do Instagram"
          />
          <meta property="og:site_name" content="InstaSorteio" />
          <meta property="og:type" content="website" />
          <meta
            property="twitter:title"
            content="Realize sorteios criativos no Instagram - InstaSorteio"
          />
          <meta
            property="
twitter:description
"
            content="
Realize sorteios a partir dos comentários do Instagram de forma simples e eficiente com o InstaSorteio. Carregue mais de 1 milhão de comentários.
"
          />

          <meta
            property="og:image"
            content="https://instasorteio.com/logo/PrincipalNegativa.svg"
          />
          <meta
            property="twitter:image"
            content="https://instasorteio.com/logo/PrincipalNegativa.svg"
          />
          <meta property="twitter:card" content="summary_large_image" />
          <link rel="canonical" href="https://instasorteio.com" />
          <meta property="twitter:card" content="InstaSorteio" />
          <meta
            property="og:description"
            content="Realize sorteios a partir dos comentários do Instagram de forma simples e eficiente com o InstaSorteio. Carregue mais de 1 milhão de comentários."
          />
          <meta property="og:type" content="website" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:alt" content="InstaSorteio" />
          <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>

          <link
            rel="alternate"
            hrefLang="pt-br"
            href="https://www.instasorteio.com/pt/sorteio/instagram"
          />
          <link
            rel="alternate"
            hrefLang="en"
            href="https://www.instasorteio.com/"
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href="https://www.instasorteio.com/"
          />
        </Head>
        <body className="bg-violet-700 w-full h-full flex"></body>
      </html>
    </Component>
  );
}
